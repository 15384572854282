import React from "react";
import classes from "./PastProjects.module.css";

const PastProjects = (props) => {
  return (
    <div>
      these are past projects
    </div>
  );
};

export default PastProjects;
